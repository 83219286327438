@import './group-popup.scss';

.group-app {
    color: #000;

    > .group-container {
        margin: 10px;
        display: flex;
        flex-direction: row;
        justify-content: stretch;

        > .group-list {
            display: flex;
            flex-grow: 1;
            margin-right: 10px;
            
            &:last-child {
                margin-right: 0px;
            }
        }
    }
}