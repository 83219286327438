.edit-groups {
    color: #000;
    display: flex;
    flex-direction: column;

    > .main-group {
        display: flex;
        flex-direction: row;

        > .type {
            padding-right: 10px;
        }

        > .value {
            display: flex;
            flex-grow: 1;
        }
    }

    > .sub-groups {
        display: flex;
        flex-direction: row;

        .remove {
            font-weight: bold;
            color: lighten($color: #000000, $amount: 50);

            &:hover {
                cursor: pointer;
                color: #000;
            }
        }
    }
}