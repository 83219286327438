@import "./components/members/members.scss";
@import "./components/groups/groups.scss";
@import "~bootstrap/scss/bootstrap";
@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

@import './variables.scss';

html, body {
  background-color: $primary;
  color: $primary-font;
}

.inline-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;

  &-focus-element {
    z-index: 11;
  }
}

.feuerwehr-app {
  > .header {
    background: $secondary;
    color: $primary-font;
    height: $header-height;
    display: flex;
    flex-direction: row;

    > .title {
      font-size: 1.8em;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .app-body {
    height: calc(100vh - #{$header-height} - 20px);
    margin: 10px;
    background: $secondary;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    > .headline {
      color: #000;
      background-color: $primary-font;
      padding: 10px 5px;
      font-weight: bold;
    }

    > .app-container {
      flex-grow: 1;
      align-content: stretch;
    }
  }
}

.scrolbar-style::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrolbar-style::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.scrolbar-style::-webkit-scrollbar-thumb
{
	background-color: $primary;
	border: 1px groove #000;
}

.modal-title {
  color: #000;
}