@import '../../variables.scss';

.member-app {
    .no-padding {
        padding: 0px;
    }

    .no-border-bottom {
        border-bottom: none;
    }

    .member-container {
        display: flex;
        flex-direction: row;
        
        .member-overview {
            width: 400px;
            padding: 10px;

            .member-list {
                margin: 0px 0px;
                display: flex;
                flex-direction: column;
                overflow-y: hidden;
                color: #000;
                border:  none;
                max-height: calc(100vh - 140px);

                .border {
                    overflow-y: auto;
                    max-height: 100%;
                }

                .member {
                    font-weight: bold;
                    border-bottom: 1px groove #000;

                    &:last-child {
                        margin-bottom: 0px;
                        border-bottom: none;
                    }

                    &:hover, &.selected {
                        background-color: $highlight;
                        cursor: pointer;
                    }
                }
            }
        }

        .member-details {
            margin-top: 10px;
            flex-grow: 1;
            color: #1a202c;
            max-height: calc(100vh - 140px);
            overflow-y: auto;

            > .container {
                background-color: #CCC;
                color: #000;

                > .header {
                    padding: 10px;
                    background-color: $primary;
                    border: 1px solid #CCC;
                    color: $primary-font;
                    font-weight: bold;
                }
            }
            .lehrgang-body {
                overflow-y: auto;
                max-height: 100%;
            }

            .details-body {
                overflow-y: auto;
                max-height: 100%;
            }

            .activity-body {
                overflow-y: auto;
                max-height: 100%;
            }
        }
    }
}

.container-without {
    margin: 0px !important;
    padding: 0px !important;
}

.profile-container {

}

.g26-active-border {
    border: 3px groove rgb(40, 170, 1);
    box-shadow: 10px 10px 20px #000;;
}

.g26-notactive-border {
    border: 3px groove rgb(138, 0, 0);
    box-shadow: 10px 10px 20px #000;;
}

.lehrgang { 

}
.infos { 

}
.activity { 

}


.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -3px !important;
    margin-left: -3px !important;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}