/* Colors */
$primary: #212121;
$secondary: #363636;
$highlight: #FFC700;
$primary-font: #D4D4D4;
$secondary-font: #FFFFFF;

$enable-responsive-font-sizes: true;

/* Heights */
$header-height: 52px;